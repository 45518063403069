<template>
    <div>
        <!--begin::customer-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title">
                    <h3 class="card-label">
                        {{ $t('banks.banks')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
            </div>

            <div class="card-body">
                    <b-tabs content-class="mt-3">
                        <b-tab :title="$t('activity_log.basic_information')" active>
                            <br>
                            <div>
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <th>{{$t('banks.name')}}</th>
                                            <td>{{data.name}}</td>
                                        </tr>

                                        <tr>
                                            <th>{{$t('banks.bank_no')}}</th>
                                            <td>{{data.bank_no}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('banks.notes')}}</th>
                                            <td>{{data.notes}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('banks.withdraw')}}</th>
                                            <td>{{data.withdraw_name}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('banks.deposit')}}</th>
                                            <td>{{data.deposit_name}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('created_at')}}</th>
                                            <td>{{data.created_at}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                          <div v-if="data.items_list">
                            <!--  -->
                            <h3 class="font-weight-bold">{{ $t('banks.balances') }}</h3>
                            <table class="table">
                              <tbody>
                              <tr>
                                <th>{{$t('banks.balance')}}</th>
                                <th>{{$t('banks.currency')}}</th>
                              </tr>
                              <tr v-for="balance in data.items_list" :key="balance">
                                <td>{{ balance.balance }}</td>
                                <td>{{ balance.currency_name }}</td>
                              </tr>
                              </tbody>
                            </table>
                          </div>

                        </b-tab>
                        <b-tab :title="$t('activity_log.activity_log')">
                            <br>
                            <activity-log :inner-key="key" :id="id"></activity-log>
                        </b-tab>
                    </b-tabs>
                </div>
        </div>
        <!--end::customer-->
    </div>
</template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import ActivityLog from "@/view/content/widgets/details/ActivityLog";

    export default {
        name: "view-banks",
        components:{'activity-log': ActivityLog},
        data() {
            return {
                mainRoute: 'finances/banks',
                id: this.$route.params.id? this.$route.params.id : null,
                key: 'Bank',
                data: {},
            };
        },

        methods: {
            async getData() {
                ApiService.get(`${this.mainRoute}/${this.id}`).then((response) => {
                    this.data = response.data.data;
                });
            },
        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.banks_management"),route:'/finances/banks'}, {title: this.$t('view')}]);

            if (this.id) {
                this.getData();
            }

        },
    };
</script>